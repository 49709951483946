import {
  getStripeAnonymousCustomerPortal,
  getStripeCustomerPortal,
} from '@aninix/api'
import { FullPageLoader } from '@aninix/app-design-system'
import { observer } from 'mobx-react-lite'
import * as React from 'react'

import { GeneralAccessRestricted } from '../../components/status-pages'
import { useSessionStore } from '../../stores'

export type Props = {}
export const ManageSubscription: React.FCC<Props> = observer(() => {
  const { isInitiated, userId, teamId } = useSessionStore()
  const [url, setUrl] = React.useState<string | undefined>()

  const redirectToCustomerPortal = React.useCallback(async () => {
    try {
      const result =
        !!teamId && !!userId
          ? (await getStripeAnonymousCustomerPortal()).data
          : (
              await getStripeCustomerPortal({
                teamId: !!teamId ? teamId : undefined,
              })
            ).data
      setUrl(result.url)
      return result.url
    } catch (err: unknown) {
      // @TODO: add custom error handling logic
      console.error('Error happen while generating customer portal url', err)
      setUrl('')
      return ''
    }
  }, [teamId, userId])

  React.useEffect(() => {
    if (!isInitiated) {
      return
    }

    redirectToCustomerPortal().then((url) => {
      if (url !== '') {
        window.location.assign(url)
      }
    })
  }, [isInitiated])

  if (url === '') {
    return <GeneralAccessRestricted />
  }

  return <FullPageLoader />
})

ManageSubscription.displayName = 'ManageSubscription'
