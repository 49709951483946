import { AnalyticsEvent, useAnalytics } from '@aninix/analytics'
import { httpClient } from '@aninix/api'
import { useQuery } from '@tanstack/react-query'
import * as React from 'react'

import { ApiGetResponse } from '@aninix/api/helpers'
import { config } from '../../config'
import { useSessionStore } from '../../stores'
import { IUpgradeToProUseCase, useCurrentSpaceStore } from '../../use-cases'

type Payload = {
  upgradeToProUseCase: IUpgradeToProUseCase
  teamId?: string
  onDone?: () => void
}

export interface IBlockedViewInteractor {
  isAnnual: boolean
  toggleAnnual: () => void
  upgradeToPro: () => Promise<void>
  cancel: () => void
  state: IUpgradeToProUseCase['state']
  monthlyPrice: number
  annualPrice: number
  savings: number
  isLoading: boolean
  existingProjectsUrl: string
  account: { name: string; avatarUrl: string; email: string }
  error?: string
}
export function usePaymentRequiredViewInteractor({
  upgradeToProUseCase,
  teamId,
  onDone,
}: Payload): IBlockedViewInteractor {
  const analytics = useAnalytics()
  const [error, setError] = React.useState<string | undefined>()
  const sessionStore = useSessionStore()
  const { currentUser } = useCurrentSpaceStore()
  const { data: prices, isLoading } = useQuery<
    Record<
      'monthly' | 'annual',
      { amount: number; period: 'monthly' | 'annual' }
    >
  >({
    queryKey: [teamId, sessionStore.userId],
    queryFn: async () => {
      const { data } = await httpClient.get<ApiGetResponse<'/prices'>>(
        teamId == null
          ? `${config.apiUrl}/prices`
          : `${config.apiUrl}/prices?teamId=${teamId}`
      )
      return data
    },
  })
  const currentTeam = React.useMemo(
    () => currentUser?.teams.find((t) => t.id === teamId),
    [currentUser, teamId]
  )

  const upgradeToPro = React.useCallback(async () => {
    const result = await upgradeToProUseCase.start()

    if (result == null) {
      setError('Error happen when generating url')
      return
    }

    window.open(result.url, '_blank')
    analytics.track({
      eventName: AnalyticsEvent.PaywallUpgradeClicked,
      properties: { team: teamId },
    })
  }, [teamId, analytics])

  // @TODO: make dynamic pricing
  const monthlyPrice = React.useMemo(() => {
    if (prices == null) {
      return 0
    }

    if (upgradeToProUseCase.isAnnual) {
      return Math.round((prices.annual.amount / 12) * 100) / 100
    }

    return prices.monthly.amount
  }, [upgradeToProUseCase.isAnnual, prices])

  React.useEffect(() => {
    if (upgradeToProUseCase.state === 'success') {
      onDone?.()
    }
  }, [upgradeToProUseCase.state])

  React.useEffect(() => {
    analytics.track({
      eventName: AnalyticsEvent.PaywallViewed,
      properties: { team: teamId },
    })
  }, [])

  return {
    isAnnual: upgradeToProUseCase.isAnnual,
    toggleAnnual: upgradeToProUseCase.toggleAnnual,
    upgradeToPro,
    cancel: upgradeToProUseCase.cancel,
    state: upgradeToProUseCase.state,
    monthlyPrice,
    annualPrice: prices?.annual.amount ?? 0,
    savings: Math.abs(
      (prices?.annual.amount ?? 0) - (prices?.monthly.amount ?? 0) * 12
    ),
    isLoading,
    existingProjectsUrl:
      teamId == null
        ? `/my/projects?aa=${sessionStore.userId}`
        : `/teams/${teamId}/projects?aa=${sessionStore.userId}`,
    account: {
      name: teamId != null ? currentTeam!.name : sessionStore.name,
      avatarUrl:
        teamId != null
          ? (currentTeam!.avatarUrl ?? '/images/defaultTeamAvatar.png')
          : sessionStore.avatarUrl,
      email: teamId != null ? currentTeam!.domain : sessionStore.email,
    },
    error: error ?? upgradeToProUseCase.errorMessage,
  }
}
