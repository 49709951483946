import { useMutation } from '@tanstack/react-query'

import { ApiPostRequestBody, ApiPostResponse } from './helpers'
import { httpClient } from './http-client'

export function useGetStripeCustomerPortal() {
  return useMutation({
    async mutationFn(
      payload: ApiPostRequestBody<'/payments/stripe-customer-portal'>
    ) {
      return httpClient.post<
        ApiPostResponse<'/payments/stripe-customer-portal'>
      >('/payments/stripe-customer-portal', payload)
    },
  })
}

export function getStripeAnonymousCustomerPortal() {
  return httpClient.post<
    ApiPostResponse<'/payments/anonymous-stripe-customer-portal'>
  >('/payments/anonymous-stripe-customer-portal')
}

export function getStripeCustomerPortal(
  payload: ApiPostRequestBody<'/payments/stripe-customer-portal'>
) {
  return httpClient.post<ApiPostResponse<'/payments/stripe-customer-portal'>>(
    '/payments/stripe-customer-portal',
    payload
  )
}
